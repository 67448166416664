<template>
    <div>
        <!-- MAIN CONTENT ================================================== -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                <!-- Breadcrumb -->
                <div class="position-fixed w-100" style="top:0;z-index:99;transform:translateX(-1px);">
                    <div class="d-flex mb-4 pt-3 pb-3 border-bottom align-items-center bg-white">
                        <a href="javascript:history.back()"><span class="fe fe-arrow-left mr-1"></span>Kembali</a>
                        <span class="text-muted mx-2">|</span>
                        <div class="text-muted">Data Registrasi</div>
                        <span class="text-muted mx-2"><span class="fe fe-arrow-right-circle mr-1 text-sm"></span></span>
                        <div class="text-muted">{{ detailsetformulir.sub_unit_title }} {{ detailsetformulir.nama_formulir }} {{ detailsetformulir.keterangan }}</div>
                    </div>
                </div>
                <!-- -->
                <div class="d-block mb-4 pt-5 mt-3">
                    <div class="d-flex align-items-center">
                        <div class="mr-3">
                            <img src="https://scolacdn.com/frontend/argonne-img/user-icon-teacher.png" width="38">
                        </div>
                        <div>
                            <h1 class="header-title">
                                Data Registrasi
                            </h1>
                            <div class="d-block">
                                Menu ini digunakan untuk mengelola registrasi peserta.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card shadow-sm">
                    <!-- Card Header -->
                    <div class="d-flex align-items-center justify-content-between px-4 py-3 border-bottom">
                        <div class="d-flex align-items-center">
                            <div class="mr-4 border-right pr-4"><span class="small">Kode Formulir:</span>
                                <div class="d-block font-weight-bold text-sm">{{ detailsetformulir.kode_formulir }}</div>
                            </div>
                            <div class="mr-4 border-right pr-4"><span class="small">Unit:</span>
                                <div class="d-block font-weight-bold text-sm">{{ detailsetformulir.unit_title }}</div>
                            </div>
                            <div class="mr-4 border-right pr-4"><span class="small">Sub-unit:</span>
                                <div v-if="detailsetformulir.sub_unit_id" class="d-block font-weight-bold text-sm">{{ detailsetformulir.sub_unit_title }}</div>
                                <div v-else class="d-block font-weight-bold text-sm">-</div>
                            </div>
                            <div class="mr-4 border-right pr-4"><span class="small">Periode:</span>
                                <div class="d-block font-weight-bold text-sm">{{ detailsetformulir.nama_periode }}</div>
                            </div>
                        </div>
                        <div>
                            <div class="d-flex align-items-center">
                                <div class="mr-3 text-right">
                                    <span class="small">Peserta</span>
                                    <div class="d-block font-weight-bold">{{ totalStudent }} siswa</div>
                                </div>
                                <div class="avatar-sm rounded-lg bg-primary-soft">
                                    <div class="d-flex w-100 h-100 align-items-center justify-content-center">
                                        <span class="fe fe-user h3 m-0 text-primary"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- -->
                    <div class="card-body">
                        <div class="d-block border-bottom pb-3">
                            <div class="d-flex align-items-center mb-3">
                                <div class="avatar bg-primary mr-3 rounded-circle mt-n1">
                                    <div class="d-flex w-100 h-100 align-items-center justify-content-center">
                                        <span class="fe fe-file h3 m-0 text-white"></span>
                                    </div>
                                </div>
                                <div class="mt-1">
                                    <div class="h2 mb-1">{{ detailsetformulir.nama_formulir }}</div>
                                    <div class="d-block">Gelombang <span class="fe fe-chevron-right mx-1 text-muted"></span> {{ detailsetformulir.nama_gelombang }}</div>
                                </div>
                            </div>
                            <div class="text-sm d-block px-3 py-2 bg-light rounded">
                                <table>
                                    <tr>
                                        <td class="pr-2">
                                            <span class="fe fe-info mr-2 text-muted"></span>Ket:
                                        </td>
                                        <td v-if="detailsetformulir.keterangan">{{ detailsetformulir.keterangan }}</td>
                                        <td v-else>-</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <!-- Nav -->
                        <ul class="nav nav-tabs nav-overflow header-tabs">
                            <li class="nav-item">
                                <a @click="currentTabComponent = 'all'" class="nav-link pb-3" v-bind:class="{'active' : currentTabComponent == 'all'}" style="cursor:pointer;" data-toggle="tab" aria-selected="true">
                                    <span class="notification-dot left"></span>Semua
                                </a>
                            </li>
                            <li class="nav-item" v-for="(step, index) in listStep" :key="index">
                                <a @click="currentTabComponent = step.url" class="nav-link pb-3" v-bind:class="{'active' : currentTabComponent == step.url}" style="cursor:pointer;" data-toggle="tab" aria-selected="true">
                                    <span class="notification-dot left"></span>{{ step.label }}
                                </a>
                            </li>
                            <li class="nav-item">
                                <a v-on:click="openStep()" class="nav-link pb-3" v-bind:class="{'active' : currentTabComponent == 'cicilan' }" style="cursor:pointer;" >
                                    <span class="notification-dot left"></span>Cicilan
                                </a>
                            </li>
                        </ul>
                        <!-- -->
                    </div>
                </div>
                <!-- CONTENT -->
                <!-- <keep-alive> -->
                <component :is="currentTabComponent"></component>
                <!-- </keep-alive> -->
            </div>
        </div>
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    import all from "@/components/admin/Registrasi_all.vue"
    import pendaftaran from "@/components/admin/Registrasi_pendaftaran.vue"
    import pembayaran from "@/components/admin/Registrasi_pembayaran.vue"
    import seleksi from "@/components/admin/Registrasi_seleksi.vue"
    import tinjauan from "@/components/admin/Registrasi_tinjauan.vue"
    import cicilan from "@/components/admin/Registrasi_cicilan.vue"
    export default {
        components: {
            all,
            pendaftaran,
            pembayaran,
            seleksi,
            tinjauan,
            cicilan,
        },
        data() {
            return {
                currentTabComponent: 'all',
                baseUrl: this.$apiconfig,
                listStep: [],
                detailsetformulir: {
                    kode_formulir: '',
                    nama_formulir: '',
                    unit_id: '',
                    sub_unit_id: '',
                    jadwal_tes: '',
                    url_tes: '',
                    informasi_tes: '',
                    payment_gateway_information: '',
                    external_gateway_information: '',
                    informasi_reg_ulang: '',
                    countsiswa: ''
                },
                fetching: true,
                totalStudent: 0
            }
        },
        created() {
            this.getDataFormulir();
        },
        methods: {
            // CHANGE PAGE PAGINATION
            changePage() {
                localStorage.setItem("page_registration_detail", this.pagination.currentPage);
                this.getData();
            },
            getDataFormulir() {
                // GET DATA detailsetformulir
                this.$http.get(this.baseUrl + `admin/gelombang/detail/formulir?id=${this.$route.params.id}`)
                    .then(response => {
                        this.detailsetformulir = response.data.data[0]
                        this.listStep = response.data.data[0].list
                    })
                    .catch(() => {
                        this.fetching = false;
                    });
            },
            openStep: function() {
                this.currentTabComponent = 'cicilan';
            },
        }
    }
</script>