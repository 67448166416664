<template>
    <div>
        <!-- MAIN CONTENT ================================================== -->
        <div class="tab-content">
            <div class="tab-pane show active" id="anggota-tab" role="tabpanel">
                <div class="card">
                    <div class="d-block p-4 border-bottom">
                        <div class="row align-items-center">
                            <div class="col-md-3 pr-md-0">
                                <select class="form-control custom-select form-control-rounded shadow-sm text-sm" v-model="type_formulir" v-on:change="getData()">
                                    <option value="">Lihat Semua Asal Sekolah</option>
                                    <option v-for="row in dataTypeRegister" :key="row.title">{{row.title}}</option>
                                </select>
                            </div>
                            <div class="col-md-2 pr-md-0">
                                <select class="form-control custom-select form-control-rounded shadow-sm text-sm" v-model="type_kategori" v-on:change="getData()">
                                    <option value="">Lihat Semua Kategori</option>
                                    <option v-for="row in listKategori" :key="row.id" :value="row.id">{{row.kategori}}</option>
                                </select>
                            </div>
                            <div class="col-md-5 pr-md-0">
                                <!-- Search -->
                                <form>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text text-sm">
                                                <i class="fe fe-search"></i>
                                            </span>
                                        </div>
                                        <input type="search" class="form-control text-sm search" v-model="keywords" v-on:keyup.enter="getData()" placeholder="Cari berita...">
                                    </div>
                                </form>
                            </div>
                            <div class="col-md-2">
                                <download-excel :fetch="getDataExport" :name="fileName" :header="header">
                                    <button class="btn btn-block btn-outline-primary text-sm"><span class="fe fe-download-cloud mr-2"></span>Export to excel</button>
                                </download-excel>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-sm table-hover table-striped  card-table">
                            <thead>
                                <tr>
                                    <th class="text-center pr-0">No</th>
                                    <th>Kode registrasi</th>
                                    <th>Nama</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Kategori</th>
                                    <th>Status</th>
                                    <th class="text-right">Action</th>
                                </tr>
                            </thead>
                            <tbody v-if="dataRegister.length == 0 && !fetching">
                                <tr>
                                    <td colspan="8">
                                        <div class="alert alert-light m-0" role="alert">
                                            <div class="d-flex align-items-center">
                                                <div>Data tidak tersedia..</div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-if="fetching">
                                <tr>
                                    <td colspan="8">
                                        <div class="alert alert-light m-0" role="alert">
                                            <div class="d-flex align-items-center">
                                                <div class="spinner-border spinner-border-sm mr-3" role="status"></div>
                                                <div>Sedang mengambil data..</div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-if="!fetching" class="list">
                                <tr v-for="(register, index) in dataRegister" :key="index">
                                    <td class="text-center pr-0">
                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                    </td>
                                    <td>
                                        {{ register.kode_registrasi }}
                                    </td>
                                    <td>
                                        {{ register.fullname }}
                                    </td>
                                    <td>
                                        {{ register.email }}
                                    </td>
                                    <td>
                                        {{ register.phone }}
                                    </td>
                                    <td>
                                        {{ register.kategori }}
                                    </td>
                                    <td>
                                        <div v-if="register.label == 'pending'">
                                            <span class="badge badge-light mr-2">:: {{ register.statusPendaftaran }}</span>
                                        </div>
                                        <div v-if="register.label == 'waiting'">
                                            <span class="badge badge-primary mr-2">:: {{ register.statusPendaftaran }}</span>
                                        </div>
                                        <div v-if="register.label == 'applied'">
                                            <span class="badge badge-success mr-2">:: {{ register.statusPendaftaran }}</span>
                                        </div>
                                        <div v-if="register.label == 'rejected'">
                                            <span class="badge badge-danger mr-2">:: {{ register.statusPendaftaran }}</span>
                                        </div>
                                        <div v-if="register.label == 're-check'">
                                            <span class="badge badge-warning mr-2">:: {{ register.statusPendaftaran }}</span>
                                        </div>
                                    </td>
                                    <td class="orders-status text-right">
                                        <router-link :to="{ name:'registrasi.detail',params: { id: register.id }}" class="btn btn-primary btn-sm"><i class="fe fe-eye mr-2"></i>Lihat</router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-footer">
                        <div style="float:right">
                            <!-- <ul class="pagination mb-0"></ul> -->
                            <v-pagination v-if="pagination.totalPages > 0" v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="changePage()"></v-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    export default {
        components: {
            vPagination
        },
        data() {
            return {
                fileName: "data-pendaftaran",
                header: "Registrasi Step Seleksi",
                baseUrl: this.$apiconfig,
                dataRegister: [],
                listStep: [],
                listKategori: [],
                detailsetformulir: {
                    kode_formulir: '',
                    nama_formulir: '',
                    unit_id: '',
                    sub_unit_id: '',
                    jadwal_tes: '',
                    url_tes: '',
                    informasi_tes: '',
                    payment_gateway_information: '',
                    external_gateway_information: '',
                    informasi_reg_ulang: '',
                    countsiswa: ''
                },
                fetching: true,
                dataTypeRegister: [],
                type_formulir: '',
                type_kategori: '',
                keywords: '',
                pagination: {
                    currentPage: 1,
                    totalPages: 0,
                    limit: 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
            }
        },
        created() {
            if (localStorage.getItem("page_registration_detail") !== null) {
                this.pagination.currentPage = parseInt(localStorage.getItem("page_registration_detail"));
            }
            this.getDataTypeRegistrasi();
            this.getKategori();
            this.getData();
        },
        methods: {
            // CHANGE PAGE PAGINATION
            changePage() {
                localStorage.setItem("page_registration_detail", this.pagination.currentPage);
                this.getData();
            },
            getData(reset = false) {
                if (reset) {
                    this.pagination.currentPage = 1;
                    this.pagination.totalPages = 0;
                    localStorage.setItem("page_registration_detail", this.pagination.currentPage);
                }
                // GET DATA REGISTER
                this.dataRegister = []
                this.$parent.totalStudent = 0;
                this.$http.get(this.baseUrl + `admin/registrasi/per-seleksi`, {
                        params: {
                            setup_formulir_id: this.$route.params.id,
                            type_formulir: this.type_formulir,
                            kategori: this.type_kategori,
                            keywords: this.keywords,
                            page: this.pagination.currentPage,
                        }
                    })
                    .then((response) => {
                        if (response.data.status) {
                            this.dataRegister = response.data.data;
                        }
                        this.pagination.totalPages = response.data.total_page;
                        this.$parent.totalStudent = response.data.total_data;
                        this.fetching = false;
                    }).catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    })
            },
            getDataTypeRegistrasi() {
                // GET DATA REGISTER
                this.dataTypeRegister = [];
                this.$http.get(this.baseUrl + `admin/gelombang/detail/type_formulir`)
                    .then((response) => {
                        this.dataTypeRegister = response.data.data;
                        if (response.data.status == false) {
                            this.dataTypeRegister = []
                        }
                        this.fetching = false;
                    }).catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    })
            },
            getKategori() {
                this.listKategori = [];
                this.$http.get(this.baseUrl + `admin/registrasi/data/kategori`)
                    .then((response) => {
                        this.listKategori = response.data.data;
                        this.fetching = false;
                    }).catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            async getDataExport() {
                const response = await this.$http.get(this.baseUrl + `admin/registrasi/data/export-step-seleksi`, {
                    params: {
                        id: this.$route.params.id,
                        type_formulir: this.type_formulir
                    }
                });
                return response.data.data;
            }
        }
    }
</script>